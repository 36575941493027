export enum TokenStatusEnum {
  HOLD = 1,
  ON_SALE = 2,
  LOCKING = 3,
  DESTROYED = 4,
}

export const TokenStatusEnumMaps: Record<string, string> = {
  [TokenStatusEnum.HOLD]: "持有中",
  [TokenStatusEnum.ON_SALE]: "寄售中",
  [TokenStatusEnum.LOCKING]: "锁定中",
  [TokenStatusEnum.DESTROYED]: "已销毁",
};

export enum TokenSourceEnum {
  MINT = 1,
  TRADE = 2,
  AIRDROP = 3,
  COMPOSE = 4,
  EXCHANGE = 5,
}

export const TokenSourceEnumMaps: Record<string, string> = {
  [TokenSourceEnum.MINT]: "铸造",
  [TokenSourceEnum.TRADE]: "买入",
  [TokenSourceEnum.AIRDROP]: "空投",
  [TokenSourceEnum.COMPOSE]: "合成",
  [TokenSourceEnum.EXCHANGE]: "兑换",
};
