export enum NoticeTypeEnum {
  ACTIVITY = 1,
  NEW = 2,
  SYNTHESIS = 3,
  OPERATE = 4,
  CONSIGNMENT = 5,
  EXCHANGE = 6,
  SHOW = 7,
  EMPOWERMENT = 8,
  AIRDROP = 9,
  WHITELIST = 10,
}

export const NoticeTypeEnumMaps: Record<string, string> = {
  [NoticeTypeEnum.ACTIVITY]: "活动公告",
  [NoticeTypeEnum.NEW]: "上新公告",
  [NoticeTypeEnum.SYNTHESIS]: "合成公告",
  [NoticeTypeEnum.OPERATE]: "运营公告",
  [NoticeTypeEnum.CONSIGNMENT]: "寄售公告",
  [NoticeTypeEnum.EXCHANGE]: "置换公告",
  [NoticeTypeEnum.SHOW]: "赋能展示",
  [NoticeTypeEnum.EMPOWERMENT]: "赋能公告",
  [NoticeTypeEnum.AIRDROP]: "空投公告",
  [NoticeTypeEnum.WHITELIST]: "白名单",
};
