export enum OrderStatusEnum {
  PENDING = 1,
  COMPLETED = 2,
  CANCELED = 3,
  WAIT_DIV = 4,
  REFUNDED = 5,
  SOLD = 6,
}

export const OrderStatusEnumMaps = {
  [OrderStatusEnum.PENDING]: "等待支付",
  [OrderStatusEnum.COMPLETED]: "转入成功",
  [OrderStatusEnum.CANCELED]: "订单已取消",
  [OrderStatusEnum.WAIT_DIV]: "待分账",
  [OrderStatusEnum.REFUNDED]: "已退款",
  [OrderStatusEnum.SOLD]: "已售出",
};

export enum OrderTypeEnum {
  INITIAL = 1,
  MARKET = 2,
}
