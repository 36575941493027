export enum ActivityTypeEnum {
  COMPOSE = 1,
  DECOMPOSE = 2,
  COLOURFULL = 3,
  EXCHANGE = 4,
  LOTTERY = 5,
}

export const ActivityTypeEnumMaps: Record<string, string> = {
  [ActivityTypeEnum.COMPOSE]: "合成",
  [ActivityTypeEnum.DECOMPOSE]: "分解",
  [ActivityTypeEnum.COLOURFULL]: "缤纷",
  [ActivityTypeEnum.EXCHANGE]: "置换",
  [ActivityTypeEnum.LOTTERY]: "抽奖",
};
